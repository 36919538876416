import request from '@/service/lib/request';
// 书籍管理

/**
 * 分页
 * @author lb
 */
export function bookAiPage(data) {
    return request({
      url: '/book_info/book_ai_page',
      method: 'post',
      data
    });
}

/**
 * 新增/修改
 * @author lb
 */
export function bookAiEdit(data) {
    return request({
      url: '/book_info/book_ai_edit',
      method: 'post',
      data
    });
}

/**
 * 根据ID刷新书籍信息
 * @author lb
 */
export function bookAiRefresh(data) {
    return request({
      url: '/book_info/book_ai_refresh',
      method: 'post',
      data
    });
}

/**
 * 分页获取纵横小说
 * @author lb
 */
export function getZongHengBook(data) {
    return request({
      url: '/book_info/get_zong_heng_book',
      method: 'post',
      data
    });
}

/**
 * 改写章节
 * @author lb
 */
export function chapterAiContent(data) {
    return request({
      url: '/book_info/book_ai_content',
      method: 'post',
      data
    });
}

/**
 * 改写整书
 * @author lb
 */
export function bookAiAll(data) {
    return request({
      url: '/book_info/book_ai_all',
      method: 'post',
      data
    });
}

/**
 * 章节分页查询
 * @author lb
 */
export function bookAiChapterPage(data) {
    return request({
      url: '/book_info/book_ai_chapter_page',
      method: 'post',
      data
    });
}

/**
 * 下一章
 * @author lb
 */
export function queryChapterNext(data) {
    return request({
      url: '/book_info/book_ai_chapter_next',
      method: 'post',
      data
    });
}

/**
 * 书籍下载
 * @author lb
 */
export function bookDownloadZip(data) {
  return request({
    url: '/book_info/book_download',
    method: 'post',
    responseType: 'blob',
    data
  });
}
