<template>
  <div class="main-conent main-conent-screen main-conent-bgFFF main-conent-borderradius">
    <div class="screen-header">
        <el-row>
            <el-col :span="8">
                <el-button type="primary" icon="el-icon-plus" @click="handleAdd">新增</el-button>
            </el-col>
            <el-col :span="16" class="text-right">
                <el-input
                    class="margin-l10"
                    style="width:200px;"
                    placeholder="请输入搜索内容"
                    clearable
                    v-model="queryParams.search">
                    <i slot="suffix" class="el-input__icon el-icon-search cursor-pointer" @click="getList()"></i>
                </el-input>
            </el-col>
        </el-row>              
    </div>

    <screen-table
        class="screen-conent"
        table-class="custom-table"
        header-row-class-name="custom-table-header"
        :data="tableData">
            <el-table-column
                fixed
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="id"
                label="主键"
                width="80">
            </el-table-column>
            <el-table-column
                prop="source"
                label="来源"
                width="80">
            </el-table-column>
            <el-table-column
                prop="code"
                label="编码">
            </el-table-column>
            <el-table-column
                prop="name"
                label="书名">
                <template slot-scope='scope'>
                  <el-tooltip class="item" effect="dark" :content="scope.row.name" placement="top">
                    <span>{{ scope.row.name && scope.row.name.length > 20 ? scope.row.name.substring(0, 20) + '...' : scope.row.name }}</span>
                  </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column
                prop="createUserId"
                label="创建人"
                width="80">
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="修改时间">
                <template slot-scope='scope'>
                    <span>{{ parseTime(scope.row.updateTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
                <template slot-scope="scope">
                    <el-button @click="handleRefresh(scope.row)" type="text">拉取</el-button>
                    <el-button @click="tableEdit(scope.row)" type="text">修改</el-button>
                    <el-button @click="handleDownloadBook(scope.row)" type="text">下载</el-button>
                </template>
            </el-table-column>
    </screen-table>

    <div class="screen-footer text-right">
        <el-pagination
            background
            layout="sizes, prev, pager, next, total"
            :page-sizes="[10, 20, 50, 100]"
            :total="total"
            @current-change="handlePageNo"
            @size-change="handlePageSize"
        >
        </el-pagination>
    </div>
    
    <!-- 添加或修改对话框 -->
    <el-dialog :title='title + "（暂时只支持纵横小说、七猫小说）"' :visible.sync='open' width='500px' :before-close='cancel'>
        <el-form ref='form' :model='form' :rules='rules' label-width='130px'>
            <el-form-item label='来源' prop='source'>
              <el-radio v-model="form.source" :label="1">七猫</el-radio>
              <el-radio v-model="form.source" :label="2">纵横</el-radio>
              <el-radio v-model="form.source" :label="3">AI小说</el-radio>
            </el-form-item>
            <el-form-item label='书名' prop='name'>
                <el-input v-model='form.name' placeholder='请输入角色名称' />
            </el-form-item>
            <el-form-item label='书编码' prop='code'>
                <el-input v-model='form.code' placeholder='请输入书编码' />
            </el-form-item>
            <el-form-item label='总章节' prop='sum'>
                <el-input v-model='form.sum' placeholder='请输入总章节' />
            </el-form-item>
        </el-form>
        <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm'>确 定</el-button>
        <el-button @click='cancel'>取 消</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>

import ScreenTable from '@/components/ScreenTable';
import { bookAiPage, bookAiEdit, bookAiRefresh, bookDownloadZip } from '@/service/book';

export default {
  components: {
      ScreenTable
  },
  data () {
    return {
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        search: undefined
      },
      total: 0,
      input1: '',
      svalue2: '',
      searchDrawer: false,
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        code: [
          { required: true, message: '书编码不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '书名不能为空', trigger: 'blur' }
        ],
        source: [
          { required: true, message: '来源不能为空', trigger: 'blur' }
        ]
      },
      userIds: []
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      bookAiPage(this.queryParams).then(response => {
        this.tableData = response['data']['records'];
        this.total = response['data']['total'];
      });
    },
    handlePageNo(pageNo) {
        this.queryParams = {
            ...this.queryParams,
            pageNo
        };
        this.getList();
    },
    handlePageSize(pageSize) {
        this.queryParams = {
            ...this.queryParams,
            pageSize
        };
        this.getList();
    },
    handleClick(row) {
      console.log(row);
    },
    handleAdd() {
        this.title = '新增';
        this.open = true;
        this.form = {
          source: 2
        };
    },
    tableEdit(row){
        this.title = '修改';
        this.open = true;
        this.form = {...row};
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.resetForm('form');
    },
    /** 提交按钮 */
    submitForm: function() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          bookAiEdit(this.form).then(response => {
            if (response.code === 0) {
              this.cancel();
              this.getList();
            } else {
              this.$message({
                  type: 'info',
                  message: '异常'
              });  
            }
          });
        }
      });
    },
    tableDel(){
    },
    handleRefresh(row){
      bookAiRefresh(row).then(response => {
        if (response.code === 0) {
          this.getList();
        } else {
          this.$message({
              type: 'info',
              message: '异常'
          });  
        }
      });
    },
    handleDownloadBook(row){
      bookDownloadZip({id: row.id}).then(response => {
        let url = window.URL.createObjectURL(new Blob([response.data], {type: `application/zip;`}));
        const a = document.createElement("a");
        a.href = url;
        a.download = row.name;
        document.body.appendChild(a);
        a.style.display = "none";
        a.click();
        document.body.removeChild(a);
        this.getList();
      });
    }
  }
};
</script>